<!-- 购车咨询 -->
<template>
  <div class="BuyAdvisory">
    <div class="carTitle">
      <img :src="carDetailInfo.whiteImg" alt="" />
      <p class="van-multi-ellipsis--l2">{{ carDetailInfo.carName }}</p>
    </div>
    <Plans :source="carDetailInfo.planInfo" />
    <div class="c_cell">
      <van-field
        v-model="userName"
        label="姓名"
        placeholder="请输入您的称呼"
        input-align="right"
      />
      <van-field
        v-model="userPhone"
        label="手机号"
        maxlength="11"
        placeholder="请输入您的手机号"
        input-align="right"
      />
    </div>
    <div class="c_redBtn" v-if="userName && userPhone" @click="submit">
      立即咨询
    </div>
    <div class="c_redBtn c_disBtn" v-else>
      立即咨询
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import Plans from "./components/Plans";
import { loadClue } from "@/api/api_carInstallment";
import { mapState } from "vuex";
export default {
  name: "BuyAdvisory",
  data() {
    return {
      userName: this.$store.state.loginInfo.userName,
      userPhone: this.$store.state.loginInfo.userPhone,
      ciyInfo: this.$store.state.position, // 获取城市码值
      thirdPartyId: "yixinjinrongMzhan", // 商户ID
      subThirdPartyId: "xinche", // 子商户ID
      privateKey: "86ov71ko0j9gzth5vfrrh4fs", // 用于SHA256签名加密
      from: "from C-YIXINJINRONGMZHAN001" // 线索来源
    };
  },
  computed: {
    ...mapState(["carDetailInfo", "loginInfo"])
  },
  methods: {
    submit() {
      if (!this.$tools.verifyPhone(this.userPhone)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      const planInfo = this.carDetailInfo.planInfo[0];
      const extentParams = {
        downPaymentRatio: planInfo.downPaymentRatio, //	String	首付
        productRateDesc: planInfo.productRateDesc, //	String	利率
        productLoanPeriodDesc: planInfo.productLoanPeriodDesc, //	String	期数
        financingAmount: planInfo.financingAmount, //	String	融资额度
        masterBrandName: this.carDetailInfo.masterBrandName, //	String	品牌
        serialName: this.carDetailInfo.serialName, //	String	车系
        year: this.carDetailInfo.year, //	String	年代
        styleName: this.carDetailInfo.styleName, //	String	车型
        planName: planInfo.planName, //	String	分期方案名
        whiteImg: this.carDetailInfo.whiteImg, //	String	图片地址
        referPrice: this.carDetailInfo.styleReferPrice, //	String	指导价
        productCategory: planInfo.productCategory //	String	产品类别
      };
      console.log(this.loginInfo);
      const params = {
        thirdPartyId: this.thirdPartyId,
        from: this.from,
        name: this.userName,
        cityId: this.ciyInfo.cityId,
        extendParam: JSON.stringify(extentParams)
      };
      if (this.loginInfo.userPhone == this.userPhone) {
        extentParams.userPhoneMt = this.loginInfo.userPhoneMt;
        params.extendParam = JSON.stringify(extentParams);
      } else {
        params.phoneNo = this.userPhone;
      }
      console.log(extentParams, params);
      const src = this.$tools.encryptByDES(
        JSON.stringify(params),
        this.privateKey
      );
      const baseSrc = encodeURIComponent(src);
      const str =
        "1.0" + "UTF-8" + baseSrc + this.thirdPartyId + this.privateKey;
      const hash = CryptoJS.SHA256(str).toString();
      loadClue({
        version: "1.0",
        charset: "UTF-8",
        thirdPartyId: this.thirdPartyId,
        subThirdPartyId: this.subThirdPartyId,
        data: baseSrc,
        sign: hash
      }).then(res => {
        if (res.code === 0) {
          const that = this;
          this.$toast({
            type: "success",
            message: "提交成功，我们会尽快联系您",
            duration: 2000,
            onClose() {
              that.$router.back(-1);
            }
          });
        }
      });
    }
  },
  components: { Plans }
};
</script>
<style lang="scss" scoped>
.BuyAdvisory {
  padding: 0 0.18rem;
  background: #fff;
  height: 100%;
  .carTitle {
    display: flex;
    align-items: center;
    margin: 0.16rem 0;
    img {
      width: 0.6rem;
      height: 0.6rem;
      flex-shrink: 0;
      border-radius: 0.04rem;
      margin-right: 0.12rem;
    }
    p {
      font-size: 0.18rem;
      font-weight: bold;
      line-height: 0.25rem;
    }
  }
  .c_cell {
    margin: 0.06rem 0 0.3rem;
  }
}
</style>
